@font-face {
  font-family: 'ChicagoFLF';
  src: local('ChicagoFLF'), url('./fonts/ChicagoFLF.ttf') format('truetype');
}
@font-face {
  font-family: 'sysfont';
  src: local('sysfont'), url('./fonts/sysfont.woff') format('woff');
}

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  max-height: 100vh;
  overflow: hidden;
}

body {
  font-family: 'sysfont', sans-serif, -apple-system, BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'ChicagoFLF', sans-serif, -apple-system, BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1{
    font-size: 30px;
  }
  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 18px;
  }
  h4, p, li {
    font-size: 16px;
  }

@media(max-width: 600px) {
  h1{
    font-size: 24px;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 14px;
  }
  h4, p, li {
    font-size: 12px;
  }
}

code {
  font-family: monospace;
}
